<template>
    <v-container>        
        <h2>Добавление тендеров в мониторинг</h2>
        <div class="d-flex my-5">
            <v-radio-group v-model="lawCode">
                <v-radio 
                    v-for="({value, label}) in laws" 
                    :key="value"
                    :label="label"
                    :value="value"></v-radio>
            </v-radio-group>
        </div>
        <div class="d-flex flex-column my-3 fields-container">
                <v-textarea
                    class="text_area"
                    outlined
                    :disabled="isSendLoading"
                    label="Регистрационные номера тендера"
                    v-model="numbers"
                    hide-details=false>
                </v-textarea>
        </div>
        <ul class="d-flex flex-column total-list px-0" v-if="resultUploadedTenders">
            <p class="my-0">Результаты отправки номеров тендеров:</p>
            <li class="d-flex flex-column">
                <p class="my-0">Добавлены: {{resultUploadedTenders.added.length }}</p>
                <ul class="list">
                    <li v-for="(tender, index) of resultUploadedTenders.added" :key="tender+index">
                        {{ tender }}
                    </li>
                </ul>
            </li>
            <li class="d-flex flex-column">
                <p class="my-0">Пропущены: {{resultUploadedTenders.duplicated.length }}</p>
                <ul class="list">
                    <li v-for="(tender, index) of resultUploadedTenders.duplicated" :key="tender+index">
                        {{ tender }}
                    </li>
                </ul>
            </li>
            <li class="d-flex flex-column">
                <p class="my-0">Ошибка при добавлении: {{resultUploadedTenders.failed.length }}</p>
                <ul class="list">
                    <li v-for="(tender, index) of resultUploadedTenders.failed" :key="tender+index">
                        {{ tender }}
                    </li>
                </ul>
            </li>
        </ul>
        <div class="d-flex justify-space-between">
            <v-btn 
                color="primary"
                :disabled="isSendLoading"
                @click="handleSendData">{{isSendLoading ? 'Загрузка данных' : 'Отправить'}}</v-btn>
            <v-btn 
                outlined
                :disabled="isSendLoading"
                @click="clearAll">Очистить всё</v-btn>
        </div>
        </v-container>
</template>

<script>
import api from '../../modules/api';
export default {
    name: "AddTendersForm",
    data() {
        return {
            numbers: '',
            laws: [
                {label: '44-ФЗ', value: 'FZ44'}, 
                {label: '223-ФЗ', value: 'FZ223'}
            ],
            lawCode: 'FZ44',
            isSendLoading: false,
            resultUploadedTenders: undefined
        }
    },
    methods: {
        async handleSendData() {
            this.isSendLoading = true;
            const res = await api.postJson('/supmain/notifications/import', this.result);
            this.resultUploadedTenders = { ...res.payload }
            this.isSendLoading = false;
        },
        clearAll() {
            this.resultUploadedTenders = undefined,
            this.numbers = ''
        }
    },
    computed: {
        tenderNumbersArray() {
            return this.numbers.trim().split('\n').filter((n) => n)
        },
        result() {
            return {
                law: this.lawCode,
                tenders: this.tenderNumbersArray
            }
        }
    }
}
</script>

<style scoped lang="sass">
    .total-list, 
    .list 
        list-style-type: none
    

    .total-list 
        gap: 5px
        margin-bottom: 10px
        & > li 
            padding-left: 5px
        
    
</style>